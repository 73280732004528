import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormArray, UntypedFormBuilder, Validators} from '@angular/forms';
import {UserDTO} from '../../../model/admin-dto';
import {UsersService} from '../../../services/users.service';
import {ToastrService} from 'ngx-toastr';
import {KeycloakInfoService} from '../../../services/keycloak-info.service';


const userNotAdded = "User not added";

const userNotEdited = "User not edited";

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [KeycloakInfoService, UsersService]
})
export class AddUserComponent implements OnInit {
  @Input()
  user: UserDTO | undefined;
  @Input()
  editMode = false;
  @Input()
  roles: string[] = [];
  @Input()
  groups: string[] = [];
  @Input()
  time: string[] = [];
  @Input()
  locale: string[] = [];
  attributes: any;
  phone: any;
  show = false;
  message = '';
  errors: Map<string, string> = new Map<string, string>();

  userForm = this.fb.group({});

  constructor(public activeModal: NgbActiveModal,
              private fb: UntypedFormBuilder,
              private usersService: UsersService,
              private toast: ToastrService) {
  }


  ngOnInit(): void {
    if (this.groups.length) {
      this.userForm = this.fb.group({
        username: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern('^[a-z][a-zA-Z0-9._-]{8,20}$')]],
        firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern('^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ - . \']{2,20}$')]],
        lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern('^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ - . \']{2,20}$')]],
        email: ['', [Validators.required, Validators.email]],
        group: [this.groups[0], [Validators.required]],
        role: [this.roles[0], [Validators.required]],
        organization: ['', [Validators.maxLength(50), Validators.pattern('^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 - . \']{0,50}$')]],
        responsibleUnit: ['', [Validators.required, Validators.maxLength(8), Validators.minLength(2), Validators.pattern('^[0-9]*[A-Z]{2,8}$')]],
        time: [this.time[0], [Validators.required]],
        locale: [this.locale[0], [Validators.required]],
        phone: this.fb.array([
          this.fb.control('', [Validators.required,
            Validators.pattern('^[+]?[0-9 ]{7,19}$'),
            Validators.minLength(7), Validators.maxLength(19)])
        ])
      });
    }
    this.phone = this.userForm.get('phone') as UntypedFormArray;
    if (this.editMode) {
      this.setFormValues();
    }

  }

  private setFormValues() {
    if (this.user) {
      this.userForm = this.fb.group({
        username: [this.user.username, [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern('^[a-z][a-zA-Z0-9._-]{8,20}$')]],
        firstName: [this.user.firstName, [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern('^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ - . \']{2,20}$')]],
        lastName: [this.user.lastName, [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern('^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -. \']{2,20}$')]],
        email: [this.user.email, [Validators.required, Validators.email]],
        group: [this.user.group, [Validators.required]],
        role: [this.user.role, [Validators.required]],
        organization: [this.user.organization, [Validators.maxLength(50), Validators.pattern('^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 - . \']{0,50}$')]],
        responsibleUnit: [this.user.responsibleUnit, [Validators.required, Validators.maxLength(8), Validators.minLength(2), Validators.pattern('^[0-9]*[A-Z]{2,8}$')]],
        time: [this.user.time, [Validators.required]],
        locale: [this.user.locale, [Validators.required]],
        phone: this.fb.array([])
      });
      this.phone = this.userForm.get('phone') as UntypedFormArray;
      this.user.phone.forEach(item => {
        this.phone.push(this.fb.control(item, [Validators.required,
          Validators.pattern('^[+]?[0-9 ]{7,19}$'),
          Validators.minLength(7), Validators.maxLength(19)]));
      });
    }
  }

  handleFormSubmit() {
    this.userForm.markAllAsTouched();
    if (this.userForm.invalid) {
      return;
    }
    if (this.editMode) {
      this.editUser();
    } else {
      this.addUser();
    }

  }

  addPhone() {
    if (this.phone.controls.length <= 5) {
      this.phone.push(this.fb.control('', [Validators.required,
        Validators.pattern('^[+]?[0-9 ]{7,19}$'),
        Validators.minLength(7), Validators.maxLength(19)]));
    }
  }

  removePhone(phoneValue: any) {
    this.phone.removeAt(phoneValue);
  }

  private editUser() {
    this.errors = new Map<string, string>();
    const value = this.userForm.value as UserDTO;
    this.usersService.updateUser(value).subscribe(response => {
      if (response) {
        this.activeModal.close(true);
        this.toast.success('User edited', 'Success', {timeOut: 10000});
      } else {
        this.toast.error(userNotEdited, 'Error', {timeOut: 10000});
      }
    }, error => {
      if (error.error) {
        Object.keys(error.error).forEach(key => this.errors.set(key, error.error[key]));
      }
      this.handleBackendErrors(userNotEdited);
    });
  }

  private addUser() {
    this.errors = new Map<string, string>();
    const value = this.userForm.value as UserDTO;
    this.usersService.addUser(value).subscribe(response => {
      this.activeModal.dismiss(true);
      if (response.message === 'User added') {
        this.toast.success(response.message, 'Success', {timeOut: 10000});
      } else {
        this.toast.error(userNotAdded);
      }
    }, error => {
      if (error.error) {
        Object.keys(error.error).forEach(key => this.errors.set(key, error.error[key]));
      }
      this.handleBackendErrors(userNotAdded);
    });
  }

  private handleBackendErrors(title: string) {
    if (this.errors.has('email')) {
      this.toast.error(this.errors.get('email'), title, {timeOut: 10000});
    } else if (this.errors.has('username')) {
      this.toast.error(this.errors.get('username'), title, {timeOut: 10000});
    } else if (this.errors.has('password')) {
      this.toast.warning(this.errors.get('password'), title, {timeOut: 10000});
      this.activeModal.dismiss(true);
    } else {
      this.toast.error(title);
    }
  }


  inputUsername() {
    const value = this.userForm.controls.username.value.toLowerCase();
    this.userForm.controls.username.setValue(value);
  }

  inputResponsibleUnit() {
    const value = this.userForm.controls.responsibleUnit.value.toUpperCase();
    this.userForm.controls.responsibleUnit.setValue(value);
  }
}
